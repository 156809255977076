<template>
  <div
    v-if="$can('read', 'User')"
    style="height: inherit"
  >

    <!-- Filters -->
    <user-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Entries') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
              variant="primary"
              @click="addUserForm"
            >
              {{ `${$t('Add')} ${$t('User')}` }}
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="`${$t('Search')}...`"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        :items="getUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        hover
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Column: User -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.name)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                @click="viewUserForm(data)"
              />
            </template>
            <b-link
              :to="{ name: 'user-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <small class="text-muted">@{{ data.item.username }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ $t(textFirstUpper(data.item.role)) }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ $t(textFirstUpper(data.item.status)) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <div class="text-nowrap">
            <feather-icon
              :id="`user-row-${data.item.id}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer mr-1"
              size="16"
              @click="editUserForm(data)"
            />
            <b-tooltip
              :title="`${$t('Edit')} ${$t('User')}`"
              :target="`user-row-${data.item.id}-edit-icon`"
              :delay="{ show: 1000, hide: 50 }"
              placement="left"
            />

            <feather-icon
              :id="`user-row-${data.item.id}-view-icon`"
              icon="EyeIcon"
              size="16"
              class="cursor-pointer mr-1"
              @click="viewUserForm(data)"
            />
            <b-tooltip
              :title="`${$t('View')} ${$t('User')}`"
              :target="`user-row-${data.item.id}-view-icon`"
              :delay="{ show: 1000, hide: 50 }"
              placement="left"
            />

            <feather-icon
              :id="`user-row-${data.item.id}-delete-icon`"
              icon="TrashIcon"
              variant="light-success"
              size="16"
              class="cursor-pointer mr-1"
              @click="deleteUserForm(data)"
            />
            <b-tooltip
              :title="`${$t('Delete')} ${$t('User')}`"
              :target="`user-row-${data.item.id}-delete-icon`"
              :delay="{ show: 1000, hide: 50 }"
              placement="right"
            />

          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              {{ $t('Showing {from} to {to} of {of} entries',
                    {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
              }}
            </span></b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BPagination, BTooltip, BMedia, BAvatar,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import {
  // ref,
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import { t } from '@/@core/libs/i18n/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UserListFilters from './UserListFilters.vue'
import useUserList from './useUserList'
import userStoreModule from '../userStoreModule'

export default {
  components: {
    UserListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BTooltip,

    vSelect,
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    addUserForm() {
      this.$router.push({ name: 'user-add' })
    },
    editUserForm(data) {
      this.$router.push({ name: 'user-edit', params: { id: data.item.id } })
    },
    viewUserForm(data) {
      this.$router.push({ name: 'user-view', params: { id: data.item.id } })
    },
    deleteUserForm(data) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          store.dispatch('user-store/deleteUser', data.item.id)
            .then(() => {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Deleted'),
                text: this.$i18n.t('Your data has been deleted'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.refetchData()
            })
            .catch(error => {
              const { response, message } = error
              if (response) {
                let errorMsg = this.$i18n.t(response?.data.error)
                if (errorMsg === '') {
                  errorMsg = this.$i18n.t(response?.data.message)
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errorMsg,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              } else if (message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error?.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              }
            })
        }
      })
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'user-store'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const roleOptions = [
      { label: t('Admin'), value: 'admin' },
      { label: t('Staff'), value: 'staff' },
      { label: t('Member'), value: 'member' },
    ]

    const planOptions = [
      { label: t('Basic'), value: 'basic' },
      { label: t('Standard'), value: 'standard' },
      { label: t('Enterprise'), value: 'enterprise' },
    ]

    const statusOptions = [
      { label: t('Pending'), value: 'pending' },
      { label: t('Active'), value: 'active' },
      { label: t('Inactive'), value: 'inactive' },
    ]

    const {
      getUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      textFirstUpper,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useUserList()

    const filterUserList = JSON.parse(localStorage.getItem('filterUserList'))
    // console.log(filterUserList)
    if (filterUserList !== null) {
      searchQuery.value = filterUserList.searchQuery
      perPage.value = filterUserList.perPage
      currentPage.value = filterUserList.page
      sortBy.value = filterUserList.sortBy
      isSortDirDesc.value = filterUserList.sortDesc
      roleFilter.value = filterUserList.role
      planFilter.value = filterUserList.plan
      statusFilter.value = filterUserList.status
    }

    return {
      getUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      textFirstUpper,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.user-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/center/user.scss";
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
      .swal2-popup {
        background-color: $theme-dark-body-bg !important;
      }
  }
}
</style>
